import React, { ChangeEvent } from 'react';
import Select from '@material-ui/core/Select';
import { useTranslate } from 'react-admin';

interface Option {
  value: string;
  label: string;
}

interface CustomSelectWithTranslationsProps {
  options: Option[];
  value: string | undefined;
  onChange?: (event: ChangeEvent<HTMLSelectElement>) => void;
  emptyLabel?: string;
  disabled?: boolean;
  error?: boolean;
  translatePath: string;
}

const CustomSelectWithTranslations: React.FC<CustomSelectWithTranslationsProps> =
  ({
    options,
    value,
    onChange,
    emptyLabel,
    disabled,
    error,
    translatePath,
  }) => {
    const translate = useTranslate();
    const handleOnChange = (
      event: ChangeEvent<{ name?: string | undefined; value: unknown }>,
    ) => {
      if (onChange) {
        onChange(event as ChangeEvent<HTMLSelectElement>);
      }
    };

    return (
      <Select
        native
        value={value}
        onChange={handleOnChange}
        disabled={disabled}
        error={error}
      >
        <option value="">{emptyLabel}</option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {translate(`${translatePath}.${option.label}`)}
          </option>
        ))}
      </Select>
    );
  };

export default CustomSelectWithTranslations;
