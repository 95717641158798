import { IMMUNOTHERAPY_MODULE, PRESCRIPTIONS_MODULE, REVIEWED_TESTS_MODULE, TESTS_MODULE, TEST_ASSIGNMENT_MODULE, REVIEWED_IMMUNOTHERAPY_MODULE } from './apiUrls';

export const adminRole = [{ action: '*', resource: '*' }];

export const partnerRole = [
  { action: '*', resource: 'dashboard' },
  { action: '*', resource: TEST_ASSIGNMENT_MODULE },
  { action: '*', resource: TESTS_MODULE },
  { action: '*', resource: REVIEWED_TESTS_MODULE },
  { action: '*', resource: IMMUNOTHERAPY_MODULE },
  { action: '*', resource: REVIEWED_IMMUNOTHERAPY_MODULE },
];
export const doctorRole = [{ action: '*', resource: PRESCRIPTIONS_MODULE }];

export const pharmacyRole = [
  { action: '*', resource: 'dashboard' },
  { action: '*', resource: TESTS_MODULE },
  { action: '*', resource: REVIEWED_TESTS_MODULE },
];

export const roles = {
  admin: adminRole,
  partner: partnerRole,
  doctor: doctorRole,
  pharmacy: pharmacyRole,
};
