/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

import { useTranslate } from 'react-admin';

import './styles.scss';

interface TestResultData {
  name: string;
  parameters: any;
}

interface Range {
  color: string;
  description: string;
  interpretation: string;
  lowerLimit: number;
  lowerLimitOperator: string;
  unit: string;
  upperLimit: number;
  upperLimitOperator: string;
  displayInterpretation: string;
}

const compareValuesFunctions = {
  '<=': (x: number, y: number) => x <= y,
  '<': (x: number, y: number) => x < y,
  '>=': (x: number, y: number) => x >= y,
  '>': (x: number, y: number) => x > y,
};

const getResultRangeData = (
  ranges: Range[] | undefined,
  measuredValue: number,
) =>
  ranges &&
  ranges.find((range) => {
    const { lowerLimitOperator, lowerLimit, upperLimit, upperLimitOperator } =
      range;
    const lowerLimitFunction =
      compareValuesFunctions[
        lowerLimitOperator as keyof typeof compareValuesFunctions
      ];
    const upperLimitFunction =
      compareValuesFunctions[
        upperLimitOperator as keyof typeof compareValuesFunctions
      ];

    if (lowerLimitFunction !== undefined && upperLimitFunction !== undefined) {
      return (
        lowerLimitFunction(measuredValue, lowerLimit) &&
        upperLimitFunction(measuredValue, upperLimit)
      );
    }

    return lowerLimitFunction(measuredValue, lowerLimit);
  });

const getResultDataForTestWithStringValue = (
  outcomes: any,
  stringValue: string,
) => outcomes.find((outcome: any) => outcome.name === stringValue);

const TestResult = ({
  data,
  testName,
  testParameter,
  testDefinition,
  isStringValueTest,
}: {
  data: TestResultData;
  testName: string;
  testParameter: any;
  testDefinition: any;
  isStringValueTest: boolean;
}) => {
  const translate = useTranslate();
  // Test parameter name example: ferritin-male-18-110
  const testParameterName = testParameter.name;
  let resultData: any;
  const testParameterDefinition = testDefinition.parameters.find(
    (parameter: any) => parameter.name === testParameterName,
  );

  if (isStringValueTest) {
    resultData = getResultDataForTestWithStringValue(
      testParameterDefinition?.outcomes,
      testParameter.stringValue,
    );
  } else {
    resultData = getResultRangeData(
      testParameterDefinition?.ranges,
      testParameter.measuredValue,
    );
  }

  return (
    <div>
      <div className="content">
        <div className="itemWrapper itemWrapper--stringValue itemWrapper--testName">
          <div className="label">{translate('testResults.parameterName')}</div>
          <div className="text">
            {translate(`parameters.${testParameterDefinition?.id}`)}
          </div>
        </div>
        {isStringValueTest && (
          <div className="itemWrapper">
            <div className="label">{translate('testResults.result')}</div>
            <div
              className="text text--stringValue"
              style={{ background: resultData?.color }}
            >
              {resultData?.displayInterpretation}
            </div>
          </div>
        )}
        {!isStringValueTest && (
          <>
            <div className="itemWrapper itemWrapper--measuredValue">
              <div className="label">
                {translate('testResults.measuredValue')}
              </div>
              <div style={{ background: resultData?.color }} className="result">
                {testParameter.measuredValue}
              </div>
            </div>
            <div className="itemWrapper">
              <div className="label">{translate('testResults.unit')}</div>
              <div className="text">{resultData?.unit}</div>
            </div>
            <div className="itemWrapper">
              <div className="label">
                {translate('testResults.interpretation')}
              </div>
              <div className="text">
                {translate(
                  `resultInterpretation.${resultData?.interpretation}`,
                )}
              </div>
            </div>
            <div className="itemWrapper">
              <div className="label">{translate('testResults.range')}</div>
              <div className="text">
                {resultData?.lowerLimit} - {resultData?.upperLimit}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default TestResult;
